<template>
  <div class="admin-sidebar-wrapper">
    <nav class="admin-sidebar" :class="{'mobile': mobileMenu, 'displayMenu': displayMobileMenu}">
      <div class="logo">
        <img src="/static/img/lamusica-lightbg1.png" alt="" />
      </div>

      <ul class="menu">

        <div class="section-header">Videos</div>
        <hr class="section-separator">
        <li v-if="modules.includes('videos')">
          <router-link to="/admin/videos" @click.native="openSubmenu('videos')">Videos</router-link>
          <ul class="submenu" :class="{ active:selected === 'videos' }">
            <li>
              <router-link to="/admin/videos">All</router-link>
            </li>
            <li>
              <router-link to="/admin/videos/new">Create</router-link>
            </li>
            <li>
              <router-link to="/admin/videos/categories-all">Categories</router-link>
            </li>
            <li>
              <router-link to="/admin/videos/ads">Section Advertisements</router-link>
            </li>
          </ul>
        </li>

        <li v-if="modules.includes('shows')">
          <router-link to="/admin/shows" @click.native="openSubmenu('shows')">Shows</router-link>
          <ul class="submenu" :class="{ active:selected === 'shows' }">
            <li>
              <router-link to="/admin/shows">All</router-link>
            </li>
            <li>
              <router-link to="/admin/shows/new">Create</router-link>
            </li>
          </ul>
        </li>

        <li v-if="modules.includes('moments')">
          <router-link to="/admin/moments" @click.native="openSubmenu('moments')">moments</router-link>
          <ul class="submenu" :class="{ active:selected === 'moments' }">
            <li>
              <router-link to="/admin/moments">All</router-link>
            </li>
            <li>
              <router-link to="/admin/moments/new">Create</router-link>
            </li>
          </ul>
        </li>

        <li v-if="modules.includes('videoSection')" class="submenu" :class="{ active:selected === 'videoSection' }">
          <router-link to="/admin/videoSection" @click.native="openSubmenu('videoSection')">Video Section Builder</router-link>
        </li>

        <div class="section-header">Others</div>
        <hr class="section-separator">
        <li v-if="modules.includes('dashboard')" class="submenu" :class="{ active:selected === 'dashboard' }">
          <router-link to="/admin/dashboard">Dashboard</router-link>
        </li>

        <li v-if="modules.includes('categories')">
          <router-link to="/admin/categories" @click.native="openSubmenu('categories')">Categories</router-link>
          <ul class="submenu" :class="{ active:selected === 'categories' }">
            <li>
              <router-link to="/admin/categories">All</router-link>
            </li>
            <li>
              <router-link to="/admin/categories/new">Create</router-link>
            </li>
          </ul>
        </li>

        <li v-if="modules.includes('playlists')">
          <router-link to="/admin/playlists" @click.native="openSubmenu('playlists')">Playlists</router-link>
          <ul class="submenu" :class="{ active:selected === 'playlists' }">
            <li>
              <router-link to="/admin/playlists">All</router-link>
            </li>
            <li>
              <router-link to="/admin/playlists/new">Create</router-link>
            </li>
          </ul>
        </li>

        <li v-if="modules.includes('podcasts')">
          <router-link to="/admin/podcasts" @click.native="openSubmenu('podcasts')">Podcasts</router-link>
          <ul class="submenu" :class="{ active:selected === 'podcasts' }">
            <li>
              <router-link to="/admin/podcasts">All</router-link>
            </li>
          </ul>
        </li>

        <li v-if="modules.includes('media')">
          <router-link to="/admin/media" @click.native="openSubmenu('media')">Media</router-link>
          <ul class="submenu" :class="{ active:selected === 'media' }">
            <li>
              <router-link to="/admin/media">Tracks</router-link>
            </li>
<!--            <li>-->
<!--              <router-link to="/admin/media/podcast">Podcasts</router-link>-->
<!--            </li>-->
            <li>
<!--              <router-link to="/admin/media/new">Create</router-link>-->
<!--              <b-btn class="add-media-modal modal-btn" @click="showAssetSongs = true">Create</b-btn>-->
              <b-btn v-b-modal.modal7 class="add-media-modal modal-btn">Create</b-btn>
            </li>
          </ul>
        </li>

        <li v-if="modules.includes('artists')">
          <router-link to="/admin/artists" @click.native="openSubmenu('artists')">Artists</router-link>
          <ul class="submenu" :class="{ active:selected === 'artists' }">
            <li>
              <router-link to="/admin/artists">All</router-link>
            </li>
            <li>
              <router-link to="/admin/artists/new">Create</router-link>
            </li>
          </ul>
        </li>

        <li v-if="modules.includes('cards')">
          <router-link to="/admin/cards" @click.native="openSubmenu('cards')">Cards</router-link>
          <ul class="submenu" :class="{ active:selected === 'cards' }">
            <li>
              <router-link to="/admin/cards">All</router-link>
            </li>
            <li>
              <router-link to="/admin/cards/new">Create</router-link>
            </li>
          </ul>
        </li>

        <li v-if="modules.includes('locations')">
          <router-link to="/admin/locations" @click.native="openSubmenu('locations')">Locations</router-link>
          <ul class="submenu" :class="{ active:selected === 'locations' }">
            <li>
              <router-link to="/admin/locations">All</router-link>
            </li>
          </ul>
        </li>

        <li v-if="modules.includes('stations')">
          <router-link to="/admin/stations" @click.native="openSubmenu('stations')">Stations</router-link>
          <ul class="submenu" :class="{ active:selected === 'stations' }">
            <li>
              <router-link to="/admin/stations">All</router-link>
            </li>
            <li>
              <router-link to="/admin/stations/new">Create</router-link>
            </li>
          </ul>
        </li>

        <li v-if="modules.includes('livestreams')">
          <router-link to="/admin/livestreams" @click.native="openSubmenu('livestreams')">Livestreams</router-link>
          <ul class="submenu" :class="{ active:selected === 'livestreams' }">
            <li>
              <router-link to="/admin/livestreams">All</router-link>
            </li>
            <li>
              <router-link to="/admin/livestreams/new">Create</router-link>
            </li>
          </ul>
        </li>

        <li v-if="modules.includes('contests')">
          <router-link to="/admin/contests" @click.native="openSubmenu('contests')">Contests</router-link>
          <ul class="submenu" :class="{ active:selected === 'contests' }">
            <li>
              <router-link to="/admin/contests">All</router-link>
            </li>
            <li>
              <router-link to="/admin/contests/new">Create</router-link>
            </li>
          </ul>
        </li>

        <li v-if="modules.includes('concerts')">
          <router-link to="/admin/concerts" @click.native="openSubmenu('concerts')">Concerts</router-link>
          <ul class="submenu" :class="{ active:selected === 'concerts' }">
            <li>
              <router-link to="/admin/concerts">All</router-link>
            </li>
          </ul>
        </li>

        <li v-if="modules.includes('users')">
          <router-link to="/admin/users" @click.native="openSubmenu('users')">Users</router-link>
          <ul class="submenu" :class="{ active:selected === 'users' }">
            <li>
              <router-link to="/admin/users">All</router-link>
            </li>
          </ul>
        </li>

        <li v-if="modules.includes('tests')">
          <router-link to="/admin/tests" @click.native="openSubmenu('tests')">Tests</router-link>
          <ul class="submenu" :class="{ active:selected === 'tests' }">
            <li>
              <router-link to="/admin/tests">One Signal</router-link>
            </li>
            <li>
              <router-link to="/admin/tests/ads">Ads</router-link>
            </li>
          </ul>
        </li>

        <li v-if="modules.includes('notifications')" class="submenu" :class="{ active:selected === 'notifications' }">
          <router-link to="/admin/notifications">Message Center</router-link>
        </li>

        <li v-if="modules.includes('configurations')">
          <router-link to="/admin/configurations" @click.native="openSubmenu('configurations')">Configurations</router-link>
          <ul class="submenu" :class="{ active:selected === 'configurations' }">
            <li>
              <router-link to="/admin/configurations">All</router-link>
            </li>
            <li>
              <router-link to="/admin/configurations/new">Create</router-link>
            </li>
          </ul>
        </li>

        <li v-if="modules.includes('support')" class="submenu" :class="{ active:selected === 'support' }">
          <router-link to="/admin/support">Support</router-link>
        </li>


        <li v-if="modules.includes('charts')">
          <router-link to="/admin/charts" @click.native="openSubmenu('charts')">Charts</router-link>
          <ul class="submenu" :class="{ active:selected === 'charts' }">
            <li>
              <router-link to="/admin/charts">All</router-link>
            </li>
          </ul>
        </li>
      </ul>

      <div class="logout" @click="logout">
        Logout
      </div>
    </nav>

    <div class="main-general-wrapper" :class="{'mobile': mobileMenu}">
      <div class="mobile-menu" >
        <i class="fas fa-bars" v-if="!displayClose" @click="displayMenu()"></i>
        <i class="fas fa-times" v-if="displayClose" @click="hideMenu()"></i>
      </div>
      <div class="welcome-user" :style="environmentStyle">
        <i class="fas fa-user"></i>
        Welcome: {{user}} {{environment}}
      </div>

      <main class="wrapper">
        <div class="container-fluid">
          <router-view :key="$route.fullPath"/>
        </div>
      </main>
    </div>
<!--    <assets-songs v-if="showAssetSongs" @closed="showAssetSongs = false"></assets-songs>-->
    <b-modal id="modal7" ref="modal7" :ok-only="true" ok-title="close" size="sm" title="Audio">
      <div class="row modal-wrapper">
        <div class="col-12">
          <div class="file-upload-form">
            <div class="label">Upload Media Files - MP3 Format</div>
            <input type="file"  @change="uploadAudio" accept="audio/*" multiple>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>


<script>
  import AssetsSongs from './assets/songs/AssetsSongs'

  export default {
    name: 'admin',
    components: {AssetsSongs},
    data () {
      return {
        selected: 'dashboard',
        formData: new FormData(),
        modules: [],
        user: null,
        windowWidth: 0,
        mobileMenu: false,
        displayMobileMenu: false,
        displayClose: false,
        showAssetSongs: false
      }
    },
    computed: {
      environmentStyle: function () {
        switch (window.location.hostname) {
          case 'localhost':
            return {backgroundColor: '#3636bb'}
          case 'admin-portal.lamusica.com':
            return {backgroundColor: 'green'}
          case 'admin-portal-stage.lamusica.com':
            return {backgroundColor: '#d4b956'}
          default:
            return {}
        }
      },
      environment: function () {
        switch (window.location.hostname) {
          case 'localhost':
            return '(local)'
          case 'admin-portal.lamusica.com':
            return '(dev)'
          case 'admin-portal-stage.lamusica.com':
            return '(stage)'
          default:
            return ''
        }
      }
    },
    mounted () {
      const permissions = localStorage.getItem('permissions')
      this.modules = permissions && permissions.length ? permissions.split(',') : []
      this.user = localStorage.getItem('user')
      let path = this.$route.path
      let pathArray = path.split('/')
      const sections = [
        'playlists',
        'categories',
        'media',
        'cards',
        'stations',
        'locations',
        'users',
        'podcasts',
        'dashboard',
        'videos',
        'moments',
        'shows',
        'artists',
        'livestreams',
        'notifications',
        'tests',
        'configurations',
        'support',
        'charts',
        'videoSection'
      ]

      pathArray.forEach((item) => {
        if (sections.indexOf(item) > -1) {
          this.openSubmenu(item)
        }
      })

      this.$nextTick(() => {
        window.addEventListener('resize', this.getWindowWidth)
        this.getWindowWidth()
      })
    },

    methods: {
      openSubmenu (selected) {
        this.selected = selected
      },
      logout () {
        localStorage.removeItem('copiedTracks')
        localStorage.removeItem('token')
        localStorage.removeItem('permissions')
        localStorage.removeItem('videoPermission')
        localStorage.removeItem('user')
        // Axios.defaults.headers.common['Authorization'] = `Bearer`
        this.$router.push('/')
      },
      uploadAudio () {
        let input = event.target
        this.$refs.modal7.hide()
        EventHandler.emit('isLoading', true)
        if (input.files && input.files.length > 0) {
          for (let i = 0; i < input.files.length; i++) {
            const file = input.files[i]
            if (/\.(mp3|m4a)$/i.test(file.name)) {
              this.formData.append('track[]', file, file.name)
            }
          }
          this.$http.post('/media',
            this.formData,
            {
              onUploadProgress: (progressEvent) => {
                this.uploadPercentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
                EventHandler.emit('uploadPercentage', this.uploadPercentage)
              }
            }).then((res) => {
            console.log(res)
            this.$notify({
              group: 'notifications',
              title: 'Media created successfully :)',
              text: '',
              type: 'success'
            })
            EventHandler.emit('isLoading', false)
            window.location.reload()
          }).catch((err) => {
            console.log(err)
            this.$notify({
              group: 'notifications',
              title: 'Sorry, something went wrong :)',
              text: '',
              type: 'error'
            })
            EventHandler.emit('isLoading', false)
          })
        } else {
          EventHandler.emit('isLoading', false)
          this.$notify({
            group: 'notifications',
            title: 'Audio file should be Mp3 or M4a format :(',
            text: '',
            type: 'error'
          })
        }
      },
      getWindowWidth (event) {
        this.windowWidth = document.documentElement.clientWidth
        if (this.windowWidth <= 1199) {
          this.mobileMenu = true
          // this.displayMobileMenu = false
        } else {
          this.mobileMenu = false
        }
      },
      displayMenu () {
        this.displayMobileMenu = true
        this.displayClose = true
      },
      hideMenu () {
        this.displayMobileMenu = false
        this.displayClose = false
      }
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.getWindowWidth)
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .admin-sidebar-wrapper {
    display: flex;
  }

  .main-general-wrapper {
    width: 100%;
    margin-left: 200px;
    .welcome-user{
      position: fixed;
      z-index: 6;
      left: 0;
      right: 0;
      text-align: right;
      background-color: #f24a4a;
      color: #fff;
      padding: 5px 20px;
      font-weight: 900;
    }
  }

  .admin-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    border-right: 1px solid #eaeaea;
    background-color: #f7f7f7;
    width: 200px;
    z-index: 0;
    flex-direction: column;
    flex: 1;
  }

  .admin-sidebar {
    .logo {
      position: absolute;
      padding: 0 45px;
      top: 58px;
      img {
        width: 100%;
      }
    }

    ul.menu{
      position: absolute;
      width: 100%;
      overflow-y:scroll;
      // top: 52%;
      height: 520px;
      // transform: translateY(-50%);
      li {
        list-style: none;
        margin-bottom: 10px;
        display: block;
        &:last-child{
          margin-right: 0;
        }
        a {
          color: #2c3e50;
          text-transform: uppercase;
          text-decoration: none;
          display: block;
          width: 100%;
          padding: 0 25px;
          font-size: 12px;
          &.router-link-exact-active{
            display: block;
          }
        }
        &.submenu.active {
          background-color: #de4242;
          margin-left: 15px !important;
          a {
            padding: 5px 10px;
            color: #fff;
          }
        }
      }
    }
    ul.submenu{
      margin-left: 30px !important;
      width: auto;
      margin-top: 8px;
      display: none;
      &.active {
        display: block;
      }
      li {
        list-style: none;
        margin-bottom: 0;
        &:last-child{
          margin-bottom: 0;
        }
        a {
          text-transform: capitalize;
          padding: 5px 10px;
          &.router-link-exact-active {
            background-color: #de4242;
            color: #fff;
          }
        }
      }
    }
  }

  .mobile-menu {
    display: none;
  }

  .mobile .mobile-menu {
    display: block;
    position: fixed;
    color: #fff;
    top: 3px;
    left: 20px;
    z-index: 12;
    font-size: 20px;
    cursor: pointer;
  }

  .admin-sidebar.mobile.displayMenu {
    display: block;
    z-index: 5;

    -webkit-box-shadow: 19px 0px 40px -16px rgba(0,0,0,0.6);
    -moz-box-shadow: 19px 0px 40px -16px rgba(0,0,0,0.6);
    box-shadow: 19px 0px 40px -16px rgba(0,0,0,0.6);
  }

  .wrapper{
    position: relative;
    flex: 1;
    min-height: 100vh;
    background: #f7f7f7;
    padding: 50px 0 30px;
  }

  .lamusica-switch {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 20px;
    margin-bottom: 0;
  }

  .lamusica-switch input {
    display:none;
  }

  .lamusica-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .lamusica-slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .lamusica-switch input:checked + .lamusica-slider {
    background-color: #de4242;
  }

  .lamusica-switch .input:focus + .lamusica-slider {
    box-shadow: 0 0 1px #de4242;
  }

  .lamusica-switch input:checked + .lamusica-slider:before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
  }

  .lamusica-slider.round {
    border-radius: 28px;
  }

  .lamusica-slider.round:before {
    border-radius: 50%;
  }

  .add-media-modal {
    background: transparent;
    background-color: transparent !important;
    border-radius: 0 !important;
    font-size: 14px !important;
    border: none !important;
    width: 100% !important;
    text-align: left !important;
    color: #2c3e50 !important;
  }

  .add-media-modal:hover{
    background-color: transparent;
    background-color: transparent !important;
    color: #2c3e50 !important;
  }

  .add-media-modal:focus, .add-media-modal.focus {
    box-shadow: none !important;
  }

  .add-media-modal:not(:disabled):not(.disabled):active,
  .add-media-modal:not(:disabled):not(.disabled).active,
  .show > .add-media-modal.dropdown-toggle{
    background: transparent !important;
    background-color: transparent !important;
    box-shadow: none !important;
    color: #2c3e50 !important;
  }

  #modal7 .label{
    margin-bottom: 10px;
  }

  #modal7 input{
    padding: 0;
  }

  .logout{
    background-color: #ececec;
    text-align: center;
    padding: 15px 0;
    font-size: 14px;
    color: #2c3e50;
    cursor: pointer;
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  @media (max-width: 768px) {
    .logo {
      display: none;
    }

    .admin-sidebar ul.menu li {
      font-size: 12px;
      margin-bottom: 12px;
    }

    .admin-sidebar ul.submenu li {
      margin-bottom: 0px;
    }
  }

</style>

<style scoped>
.section-header{
  padding-left: 4px;
  text-align: center;
  margin: 0px 25px;
  font-size: 12px;
  color: rgb(150, 150, 150);
}
.section-separator{
  width: 60%;
  margin: 2px auto 6px auto;
}
</style>